<template>
    <div v-show="show" class="view-map-wrapper">
        <div id="single-view-map"></div>
    </div>
</template>

<script>

    import {_request} from '../misc/helpers';
    // L - required object https://leafletjs.com

    export default {
        name: "ViewMap",
        data: function () {
            return {
                show: true,
                categories: [],
                coordinates: [],
                markers: [],
            }
        },
        methods: {
            getMapData() {

                const id = !this.$store.getters['isDesklineHotel'] ? this.$store.getters['getHotelId'] : this.$store.getters['getGlobalId'];

                _request({
                    method: 'POST',
                    url: this.$store.getters['getRequestUrl']('getViewMapData') + '?id=' + id + '&lang=' + this.$store.getters['getLanguage'],
                    body: {},
                }, new XMLHttpRequest()).then(response => {

                    response = JSON.parse(response.response);

                    if (response.success && response.data) {
                        this.categories = response.data.categories;
                        this.coordinates = response.data.coordinates;
                        this.markers = response.data.markers;

                        this.renderMap();
                    }

                }).catch(response => {
                    console.log('error response', response)
                })
            },
            renderMap() {

                if(!this.categories.length || !this.markers.length) {
                    this.show = false;
                    return;
                }

                let groups = [];
                let layers = [];
                let firstMarker = null;

                let latitude = null;
                let longitude = null;

                let mbUrl = 'https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw';
                let grayscale = L.tileLayer(mbUrl, {id: 'mapbox.light'});
                let streets = L.tileLayer(mbUrl, {id: 'mapbox.streets'});

                layers.push(grayscale);

                this.categories.forEach(category => {

                    let markerIcon   = L.icon({
                        iconUrl: '/img/map_icon-marker.png',
                        iconSize: [34, 55],
                        iconAnchor: [17, 55],
                        popupAnchor:  [0, -55]
                    });

                    let group = L.layerGroup();

                    
                            console.log("popup ");

                    this.markers.forEach(marker => {

                        if(marker.category_slug === category.slug) {
                            let lMarker = new L.marker([marker.latitude, marker.longitude], {icon: markerIcon}).bindPopup(this.renderModal(marker)).addTo(group);
                            if(!firstMarker) {
                                latitude = marker.latitude;
                                longitude = marker.longitude;
                                firstMarker = lMarker;
                            };

                        }
                    });

                    groups[category.category] = group;
                    layers.push(group);

                });

                this.map = L.map('single-view-map', {
                    center: [latitude || this.coordinates.latitude, longitude || this.coordinates.longitude],
                    zoom: 12,
                    layers: layers
                });

                let baseLayers = {
                    "Grayscale": grayscale,
                    "Streets": streets,
                    "Nature": L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 19 })
                };

                if(firstMarker) firstMarker.openPopup();

                L.control.layers(baseLayers, groups).addTo(this.map);

            },
            renderModal(data) {

                return `
                    <div class="view-modal-marker-inner">
                        <a href="${data.link}">
                            <div class="top">

                            </div>
                            <div class="bottom">
                                <div class="title">${data.title}</div>
                                <div class="description">${data.text}</div>
                            </div>
                        </a>
                    </div>
                    `
            },
        },
        mounted: function () {
            this.getMapData();
        }
    }
</script>

<style lang="scss" scoped>

    #single-view-map {
        height: 500px;
    }


    .view-modal-marker-inner {
        background-color: $color-true-white;

        a {
            text-decoration: none;
            color: initial;
        }

        .top {

            .img-container {
                position: relative;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 300px;

                &:after {
                    content: '';
                    display: block;
                    position: relative;
                    padding: 0 0 50% 0;
                    z-index: -1;
                }
            }
        }

        .bottom {
            padding: 10px 0 20px 0;
            font-family: "Montserrat", serif;
            font-size: 14px;
            color: $color-true-black;

            .title {
                text-transform: uppercase;
                font-weight: bold;
                color: $color-true-black;
            }

            .price-and-score {
                overflow: hidden;
                margin: 0 0 10px 0;

                .price {
                    display: inline-block;

                    span {
                        color: $color-gold-dirty;
                        font-weight: bold;
                        font-size: 20px;
                    }
                }

                .score {
                    display: inline-block;
                    float: right;
                    padding: 8px 0 0 0;
                    margin: 0;

                    span {
                        display: inline-block;
                        background-position: 0 0;
                        width: 12px;
                        height: 12px;
                        margin: 0 2px;
                        float: left;

                        &.half {
                            background-position: -14px 0;
                        }
                    }
                }
            }

            .description {
                margin: 0;
                color: $color-true-black;
            }
        }
    }

</style>
