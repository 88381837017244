let $ = window.$;

export function ajax(data, url, method){

    return new Promise((resolve, reject)=>{

        $.ajax({
            url: url,
            dataType: 'JSON',
            type: method,
            data: data,
            success: resolve,
            error:reject,
        });


    });

}

export function _request (obj, xhr) {

    return new Promise((resolve, reject) => {

        xhr.requestObject = obj;

        if (typeof obj.beforeSend !== "undefined") {
            obj.beforeSend()
        }
        xhr.open(obj.method || "GET", obj.url);
        if (obj.headers) {
            Object.keys(obj.headers).forEach(key => {
                xhr.setRequestHeader(key, obj.headers[key]);
            });
        }
        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                resolve(xhr);
            } else {
                reject(xhr);
            }
        };
        xhr.onerror = () => reject(xhr);

        if(obj.method && obj.method === 'POST') {

            let formData = new FormData();

            Object.keys(obj.body).forEach(key => {
                formData.append(key, obj.body[key])
            });

            xhr.send(formData);
        } else {
            xhr.send(obj.body);
        }

    })
}

export function serialize(obj) {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

export function getSalutations(){

    return new Promise((resolve, reject)=>{

        ajax('', '/webshop/api/getSalutations.php', 'get')
            .then((response)=>{

                resolve(response.data);

            })
            .catch(reject);
    });

}


export function _GET(parameterName) {
    var result = null,
        tmp = [];
    location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
            tmp = item.split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
}

window.ajax = ajax;
window._get = _GET;
window.getSalutations = getSalutations;
