<template>
    <div class="hotel-map-wrapper">
        <div id="single-hotel-map"></div>
    </div>
</template>

<script>

    import {_request} from '../misc/helpers';
    // L - required object https://leafletjs.com

    export default {
        name: "HotelMap",
        data: function () {
            return {
                hotel: {},
                hotels: {},
                map: {},
                markers: [],
            }
        },
        methods: {
            getMapData() {

                const id = !this.$store.getters['isDesklineHotel'] ? this.$store.getters['getHotelId'] : this.$store.getters['getGlobalId'];

                _request({
                    method: 'POST',
                    url: this.$store.getters['getRequestUrl']('getMapData') + '?id=' + id + '&lang=' + this.$store.getters['getLanguage'],
                    body: {},
                }, new XMLHttpRequest()).then(response => {

                    response = JSON.parse(response.response);

                    if (response.success && response.data) {
                        this.hotel = response.data.hotel;
                        this.hotels = response.data.hotels;

                        this.renderMap();
                    }

                }).catch(response => {
                    console.log('error response', response)
                })
            },
            renderMap() {

                let markerIcon = L.icon({
                    iconUrl: '/img/map_icon-marker-black.png',
                    iconSize: [34, 55],
                    iconAnchor: [17, 55],
                    popupAnchor:  [0, -55]
                });

                let markerIconActive = L.icon({
                    iconUrl: '/img/map_icon-marker.png',
                    iconSize: [34, 55],
                    iconAnchor: [17, 55],
                    popupAnchor:  [0, -55]
                });

                var hotelMapInfo = document.querySelector('.hotels-location-info');
                if (hotelMapInfo != undefined) {
                    var hotelMapInfoImg = hotelMapInfo.querySelector('.location-image');
                    var hotelMapInfoTitle = hotelMapInfo.querySelector('.title');
                    var hotelMapInfoLocation = hotelMapInfo.querySelector('.location');
                    var hotelMapInfoPrice = hotelMapInfo.querySelector('.price');
                }

                let hotels = L.layerGroup();

                if(this.hotels) {
                    this.hotels.forEach(hotel => {
                        var myMarker = L.marker([hotel.latitude, hotel.longitude], {icon: markerIcon}).bindPopup(this.renderModal(hotel)).addTo(hotels);
                        this.markers.push(myMarker);

                        myMarker.addEventListener("click", () => {
                            this.markers.forEach(function(item) {
                                item._icon.src = '/img/map_icon-marker-black.png';
                            });
                            
                            myMarker._icon.src = '/img/map_icon-marker.png';

                            if (hotelMapInfo != undefined) {
                                var price = `<div class="price">` + this.translate('from') + ` <span>${hotel.price} ${hotel.currency}</span> ` + this.translate('per_night') + `</div>`;
                                hotelMapInfoImg.style.backgroundImage = `url('${hotel.image}')`;
                                hotelMapInfoTitle.innerHTML = hotel.category;
                                hotelMapInfoLocation.innerHTML = hotel.location;
                                hotelMapInfoPrice.innerHTML = price;
                                hotelMapInfo.href = hotel.url;
                            }
                        });     
                        
                    })
                }

                var first = new L.marker([this.hotel.latitude, this.hotel.longitude], {icon: markerIconActive}).bindPopup(this.renderModal(this.hotel)).addTo(hotels);
                this.markers.push(first);

                let mbUrl = 'https://api.mapbox.com/styles/v1/nikoladubica/ckpfkmzjp0pog17n3i2mscur6/wmts?access_token=pk.eyJ1Ijoibmlrb2xhZHViaWNhIiwiYSI6ImNrcGZqZXkyZTBjOGcyd24wejJ6OTA0MmoifQ.N_5Qp5WdzocN7rykUF65Eg';
                
                let grayscale = L.tileLayer('https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png', { maxZoom: 19 });
                // let streets = L.tileLayer(mbUrl, {id: 'mapbox.streets'});
                let nature = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 19 });

                this.map = L.map('single-hotel-map', {
                    center: [this.hotel.latitude, this.hotel.longitude],
                    zoom: 8,
                    layers: [grayscale, hotels]
                });

                let baseLayers = {
                    "Nature": nature,
                    "Grayscale": grayscale,
                    // "Streets": streets,
                    
                };

                let overlays = {
                    "Hotels": hotels
                };

                first.openPopup();
                L.control.layers(baseLayers, overlays).addTo(this.map);

            },
            renderModal(data) {
                let price = '';
                let score = '';                

                if(data.price) {

                    data.price = data.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

                    switch (data.plugin) {
                        case 'hotels':
                            price = `<div class="price">` + this.translate('from') + ` <span>${data.price} ${data.currency}</span> ` + this.translate('per_night') + `</div>`;
                            break;
                        case 'spa':
                            price = `<div class="price">` + this.translate('from') + ` <span>${data.price} ${data.currency}</span> ` + this.translate('per_night') + `</div>`;
                            break;
                        case 'chalets':
                            price = `<div class="price">` + this.translate('from') + ` <span>${data.price} ${data.currency}</span> ` + this.translate('per_day') + `</div>`;
                            break;
                        case 'villas':
                            price = `<div class="price">` + this.translate('from') + ` <span>${data.price} ${data.currency}</span> ` + this.translate('per_day') + `</div>`;
                            break;
                        default:
                    }
                }

                if(data.score) {
                    score = `<div class="score">${this.renderStars(data.score)}</div>`;
                }

                var img = '';
                if(data.image.includes('.jpg')) {
                    var str = data.image;
                    img = str.replace(".jpg", ".webp");
                } else if(data.image.includes('.jpeg')) {
                    var str = data.image;
                    img = str.replace(".jpeg", ".webp");
                } else if(data.image.includes('.png')) {
                    var str = data.image;
                    img = str.replace(".png", ".webp");
                } else {
                    img = data.image;
                }

                return `
                    <div class="hotel-modal-marker-inner">
                        <a href="${data.url}">
                            <div class="top">
                                <div class="img-container" style="background-image: url('${img}')"></div>
                            </div>
                            <div class="bottom">
                                <div class="title">${data.category}</div>
                                <div class="price-and-score">
                                    ${price}
                                    ${score}
                                </div>
                                <div class="description">${data.item_description}</div>
                            </div>
                        </a>
                    </div>
                    `
            },
            renderStars(score) {

                let html = '';

                for (let i = 0; i < 100; i += 20) {

                    let v = Math.round((score - i) / 10);

                    if (v <= 0) {
                        html += '<span class="empty"></span>';
                    } else if (v >= 2) {
                        html += '<span style="background-image: url(\'/img/stars_rating.png\')"></span>';
                    } else {
                        html += '<span class="half" style="background-image: url(\'/img/stars_rating.png\')"></span>';
                    }
                }

                return html;
            }
        },
        mounted: function () {
            this.getMapData();
        }
    }
</script>

<style lang="scss" scoped>

    #single-hotel-map {
        height: 500px;
    }

    .hotel-modal-marker-inner {
        background-color: $color-true-white;

        a {
            text-decoration: none;
            color: initial;
        }

        .top {

            .img-container {
                position: relative;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                width: 300px;

                &:after {
                    content: '';
                    display: block;
                    position: relative;
                    padding: 0 0 50% 0;
                    z-index: -1;
                }
            }
        }

        .bottom {
            padding: 10px 0 20px 0;
            font-family: "Montserrat", serif;
            font-size: 14px;
            color: $color-true-black;

            .title {
                text-transform: uppercase;
                font-weight: bold;
            }

            .price-and-score {
                overflow: hidden;
                margin: 0 0 10px 0;

                .price {
                    display: inline-block;

                    span {
                        color: $color-gold-dirty;
                        font-weight: bold;
                        font-size: 20px;
                    }
                }

                .score {
                    display: inline-block;
                    float: right;
                    padding: 8px 0 0 0;
                    margin: 0;

                    span {
                        display: inline-block;
                        background-position: 0 0;
                        width: 12px;
                        height: 12px;
                        margin: 0 2px;
                        float: left;

                        &.half {
                            background-position: -14px 0;
                        }
                    }
                }
            }

            .description {
                margin: 0;
            }
        }
    }

</style>
