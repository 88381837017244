const state = {

    /* service data */
    xhr: new XMLHttpRequest(),

    baseUrl: typeof (PFAD) !== 'undefined' ? PFAD : location.origin + '/',

    language: typeof (LANGUAGE) !== 'undefined' ? LANGUAGE : 'en',

    requestUrl: {
        base: typeof (PFAD) !== 'undefined' ? PFAD : location.origin + '/',
        getMapData: 'webshop/api/getMapData.php',
        getViewMapData: 'webshop/api/getViewMapData.php',
    },

    /* external dependency */
    hotelId: typeof (HOTEL_ID) !== 'undefined' ? HOTEL_ID : 0,
    globalId: typeof (GLOBAL_ID) !== 'undefined' ? GLOBAL_ID : 0,
    isDesklineHotel: typeof (IS_MARIO_HOTEL) !== 'undefined' ? IS_MARIO_HOTEL : false,
    currency: typeof (CURRENCY) !== 'undefined' ? CURRENCY : 'EUR',
};

const getters = {

    /**
     *
     * @param state
     * @returns {getters.getBaseUrl|(function(*))}
     */
    getBaseUrl: state => {
        return state.baseUrl;
    },

    /**
     *
     * @param state
     * @returns {function(*): string}
     */
    getRequestUrl: state => action => {
        return state.requestUrl.base + state.requestUrl[action];
    },

    /**
     *
     * @param state
     * @returns {XMLHttpRequest}
     */
    getRequestObject: state => {
        return state.xhr;
    },

    /**
     *
     * @param state
     * @returns {string}
     */
    getHotelId: state => {
        return state.hotelId
    },

    /**
     *
     * @param state
     * @returns {number}
     */
    getGlobalId: state => {
        return state.globalId
    },

    /**
     *
     * @param state
     * @returns {getters.isDesklineHotel|(function(*))|boolean}
     */
    isDesklineHotel: state => {
        return state.isDesklineHotel
    },

    /**
     *
     * @param state
     * @returns {*}
     */
    getCurrency: state => {
        return state.currency
    },

    /**
     *
     * @param state
     * @returns {*}
     */
    getLanguage: state => {
        return state.language
    },

};

const actions = {};
const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations
}

