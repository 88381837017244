export const translation = {
    //PlanYourTrip.vue
    play_your_trip: LANG.plan_your_trip || 'LANG.plan_your_trip' || 'Plan your trip',
    check_in: LANG.check_in || 'LANG.check_in' || 'Check in',
    check_out: LANG.check_out || 'LANG.check_out' || 'Check out',
    single: LANG.single || 'LANG.single' || 'Single',
    double: LANG.double || 'LANG.double' || 'Double',
    check_availability: LANG.check_availability || 'LANG.check_availability' || 'Check availability',
    select_dates: LANG.select_dates || 'LANG.select_dates' || 'Select dates',
    please_select_your_dates: LANG.select_dates_datepicker_header || 'LANG.select_dates_datepicker_header' || 'Please select your dates and number of guest for availability',
    //AvailableDates.vue
    not_available_for: LANG.sorry_no_availability_hotel || 'LANG.sorry_no_availability_hotel' || 'Sorry, this hotel is not available for %dates%',
    dates_instead: LANG.howAboutThese || 'LANG.howAboutThese' || 'How about one of the following dates instead?',
    alternative_dates: LANG.alternative_dates || 'LANG.alternative_dates' || 'Search for alternative dates',
    //HotelMap.vue
    per_night: LANG.room_price_per_night || 'LANG.room_price_per_night ' || 'per night',
    per_day: LANG.per_day || 'LANG.per_day' || 'per day',
    //NearbyHotels.vue
    nearby_hotels: LANG.additional_hotels_nearby || 'LANG.additional_hotels_nearby' || 'Nearby hotels with availability',
    night: LANG.night || 'LANG.night' || 'Night',
    //Payment.vue
    saving_the_booking: LANG.saving_the_booking || 'LANG.saving_the_booking' || 'Please hold on a second, saving the booking.',
    //Room.vue
    no_cancellation_possible: LANG.no_cancellation_possible || 'LANG.no_cancellation_possible' || 'No cancellation possible',
    cancellation_no: LANG.cancellationNo || 'LANG.cancellationNo' || 'Non-refundable',
    photos: LANG.Photos || 'LANG.Photos' || 'Photos',
    bed_size: LANG.bed_size || 'LANG.bed_size' || 'Bed Size',
    room_size: LANG.room_size || 'LANG.room_size' || 'Room Size',
    guests: LANG.guests || 'LANG.guests' || 'Guests',
    from: LANG.from || 'LANG.from' || 'From',
    select_this_room: LANG.select_room || 'LANG.select_room' || 'Select this room',
    sorry_fully_booked: LANG.sorry_fully_booked || 'LANG.sorry_fully_booked' || 'Sorry - fully booked',
    room_price_per_night: LANG.room_price_per_night || 'LANG.room_price_per_night' || '/ Night',
    nights: LANG.nights || 'LANG.nights' || 'nights',
    modal_room_adults: LANG.modal_room_adults || 'LANG.modal_room_adults ' || '%digit% adults',
    adults: LANG.adults || 'LANG.adults' || 'Adults',
    option: LANG.option || 'LANG.option' || 'Option',
    up_to: LANG.up_to || 'LANG.up_to' || 'Up to',
    persons: LANG.persons || 'LANG.persons' || 'persons',
    room_only: LANG.mealsNO || 'LANG.mealsNO' || 'Room only',
    cancel_before: LANG.cancellationYes || 'LANG.cancellationYes' || 'Free cancellation if you cancel before',
    special_offer: LANG.specialOffer || 'LANG.specialOffer' || 'Special Offer',
    //RoomGuestDetails.vue
    guest_details: LANG.modal_please_enter_details || 'LANG.modal_please_enter_details' || 'Please enter the guest details',
    title: LANG.webshop_title || 'LANG.webshop_title' || 'Title',
    surname: LANG.webshop_surname || 'LANG.webshop_surname' || 'Surname',
    name: LANG.webshop_name || 'LANG.webshop_name' || 'Name',
    address: LANG.address || 'LANG.address' || 'Address',
    postcode: LANG.webshop_zip || 'LANG.webshop_zip' || 'Postcode',
    city: LANG.webshop_city || 'LANG.webshop_city' || 'City',
    phone: LANG.phone || 'LANG.phone' || 'Phone',
    language: LANG.webshop_country || 'LANG.webshop_country' || 'Language',
    german: LANG.german || 'german' || 'German',
    english: LANG.english || 'LANG.english' || 'English',
    french: LANG.french || 'LANG.french' || 'French',
    arabic: LANG.arabic || 'LANG.arabic' || 'Arabic',
    russian: LANG.russian || 'LANG.russian' || 'Russian',
    chinese: LANG.chinese || 'LANG.chinese' || 'Chinese',
    email: LANG.webshop_email || 'LANG.webshop_email' || 'E-mail',
    special_request: LANG.modal_special_request || 'LANG.modal_special_request' || 'Special requests?',
    arrival_at_the_hotel: LANG.modal_you_will_pay_at_arrival || 'LANG.modal_you_will_pay_at_arrival' || 'You will pay on arrival at the hotel, this is only a security check to secure your booking.',
    credit_card_details: LANG.credit_card_details || 'LANG.credit_card_details' || 'Credit card details',
    read_and_agree: LANG.webshop_privacy_policy || 'LANG.webshop_privacy_policy' || 'I have read and agree to the Privacy Policy General terms and conditions of Premium Europe.',
    privacy_policy: LANG.privacy_policy || 'LANG.privacy_policy' || 'Click here to read our Privacy Policy General terms and conditions',
    apply_voucher: LANG.booking_popup_voucher_apply_code || 'LANG.booking_popup_voucher_apply_code' || 'Apply voucher',
    have_coupon_code: LANG.booking_popup_voucher_have_code|| 'LANG.booking_popup_voucher_have_code' || 'Have coupon code?',
    code: LANG.code || 'Code',
    //RoomModal.vue
    change_option: LANG.modal_change_option || 'LANG.modal_change_option' || 'Change option',
    dates: LANG.dates || 'LANG.dates' || 'Dates',
    read_more: LANG.read_more || 'LANG.read_more' || 'Read more',
    read_less: LANG.read_less || 'LANG.read_less' || 'Read less',
    total_cost: LANG.room_price_incl_taxes || 'LANG.room_price_incl_taxes' || 'Total cost (incl. Tax)',
    //RoomOption.vue
    occupancy: LANG.occupancy || 'LANG.occupancy' || 'Occupancy',
    board: LANG.meals || 'LANG.meals' || 'Board',
    cancellation: LANG.webshop_product_cancellation || 'LANG.webshop_product_cancellation' || 'Cancellation',
    book_this_option: LANG.book_this_option || 'LANG.book_this_option' || 'Book this option',
    package_extras: LANG.package_extras || 'LANG.package_extras' || 'Package extras',
    //RoomReserved.vue
    your_reservation: LANG.your_reservation || 'your_reservation' || 'Thank you for your reservation!',
    booking_reference: LANG.booking_reference || 'booking_reference' || 'Booking reference',
    booking_confirmation: LANG.booking_confirmation || 'booking_confirmation' || 'Your booking confirmation will be sent to this email address',
    breakfast: LANG.breakfast || 'LANG.breakfast' || 'Breakfast',
    our_travel_experts: LANG.our_travel_experts || 'LANG.our_travel_experts' || 'Our travel experts will contact you after your booking to support you with pre-arrival arrangements such as booking transfers from the airport or the train station, organising special meals or yoga lessons, and any other requirements you may have to make your stay perfect!',
    contact_hotel: LANG.contact_hotel || 'LANG.contact_hotel' || 'Contact Hotel',
    do_you_need_help: LANG.do_you_need_help || 'LANG.do_you_need_help' || 'Do you need help?',
    close: LANG.close || 'LANG.close' || 'Close',
    hotel_expert: LANG.hotel_expert || 'LANG.hotel_expert' || 'Hotels expert',
    //EnquiryForm.vue
    enquiry: LANG.enquiry || 'LANG.enquiry' || 'Enquiry',
    number_of_guests: LANG.guests || 'LANG.guests' || 'Number of guests',
    budget_per_week: LANG.budget || 'LANG.budget' || 'Budget (EUR per Week)',
    note: LANG.note || 'LANG.note' || 'Note',
    send_enquiry: LANG.send_enquiry || LANG.send_enquiry || 'Send enquiry',
    thank_you: LANG.ty || 'LANG.ty' || 'Thank you!',
    forgot_something: LANG.enquiry_forgot || 'LANG.enquiry_forgot' || 'Forgot something?',
    feel_free_to_send: LANG.enquiry_send_another || 'LANG.enquiry_send_another' || 'Feel free to send another enquiry',
    your_enquiry_has_been_sent: LANG.enquiry_sent_text || 'LANG.enquiry_sent_text' || 'Your enquiry has been sent and we will get back to you on %email% shortly.',
    //Search.vue
    searchbar_placeholder: LANG.searchbar_placeholder || 'LANG.searchbar_placeholder' || '',
    searchbar_recent_searches: LANG.searchbar_recent_searches || 'LANG.searchbar_recent_searches' || '',
    searchbar_search_button: LANG.searchbar_search_button || 'LANG.searchbar_search_button' || '',
    searchbar_adults: LANG.searchbar_adults || 'LANG.searchbar_adults' || '',
    searchbar_no_search_result: LANG.searchbar_no_search_result || 'LANG.searchbar_no_search_result' || '',
    searchbar_search_results: LANG.searchbar_search_results || 'LANG.searchbar_search_results' || '',

    // Autocomplete
    villas: LANG.villas || 'LANG.villas' || 'villas',
    yacht: LANG.yacht || 'LANG.yacht' || 'yacht',
    spa: LANG.spa || 'LANG.spa' || 'spa',
    hotels: LANG.hotels || 'LANG.hotels' || 'hotels',
    chalets: LANG.chalets || 'LANG.chalets' || 'chalets',

    //Switzerland tourism
    switzerland_tourism_title: LANG.switzerland_tourism_title || 'LANG.switzerland_tourism_title' || 'Switzerland Tourism',
    switzerland_tourism_recomended: LANG.switzerland_tourism_recomended || 'LANG.switzerland_tourism_recomended' || 'Premium Europe is recommended by Switzerland Tourism.    ',
    switzerland_tourism_visit_website: LANG.switzerland_tourism_visit_website || 'LANG.switzerland_tourism_visit_website' || 'Visit website',

    // new hotels
    hotels_code_search: LANG.hotels_code_search || 'LANG.hotels_code_search' || '',
    hotels_code_book_now: LANG.hotels_code_book_now || 'LANG.hotels_code_book_now',
    hotels_code_concierge_booking: LANG.hotels_code_concierge_booking || 'LANG.hotels_code_concierge_booking',
    hotels_code_book_directly_with_hotel: LANG.hotels_code_book_directly_with_hotel || 'LANG.hotels_code_book_directly_with_hotel',
    hotels_code_luxury_hotel: LANG.hotels_code_luxury_hotel || 'LANG.hotels_code_luxury_hotel',
    hotels_code_spa_hotel: LANG.hotels_code_spa_hotel || 'LANG.hotels_code_spa_hotel',
    hotels_code_pe_extras_eligible: LANG.hotels_code_pe_extras_eligible || 'LANG.hotels_code_pe_extras_eligible',
    hotels_code_upgrade_upon_availability: LANG.hotels_code_upgrade_upon_availability || 'LANG.hotels_code_upgrade_upon_availability',
    hotels_code_bottle_of_wine: LANG.hotels_code_bottle_of_wine || 'LANG.hotels_code_bottle_of_wine',
    hotels_code_youll_receive_all_pe_extras: LANG.hotels_code_youll_receive_all_pe_extras || 'LANG.hotels_code_youll_receive_all_pe_extras',
    hotels_code_your_concierge: LANG.hotels_code_your_concierge || 'LANG.hotels_code_your_concierge',
    hotels_code_patricia: LANG.hotels_code_patricia || 'LANG.hotels_code_patricia',
    hotels_code_i_will_take_care: LANG.hotels_code_i_will_take_care || 'LANG.hotels_code_i_will_take_care',
    hotels_code_view_full_summary: LANG.hotels_code_view_full_summary || 'LANG.hotels_code_view_full_summary',
    hotels_code_rooms: LANG.hotels_code_rooms || 'LANG.hotels_code_rooms',
    hotels_code_bed_size: LANG.hotels_code_bed_size || 'LANG.hotels_code_bed_size',
    hotels_code_room_size: LANG.hotels_code_room_size || 'LANG.hotels_code_room_size',
    hotels_code_cb_doesnt_affect_price: LANG.hotels_code_cb_doesnt_affect_price || 'LANG.hotels_code_cb_doesnt_affect_price',
    hotels_code_more_photos: LANG.hotels_code_more_photos || 'LANG.hotels_code_more_photos',
    hotels_code_summary: LANG.hotels_code_summary || 'LANG.hotels_code_summary',
    hotels_code_book_hotel: LANG.hotels_code_book_hotel || 'LANG.hotels_code_book_hotel',
    hotels_code_youll_get_pe_extras: LANG.hotels_code_youll_get_pe_extras || 'LANG.hotels_code_youll_get_pe_extras',
    hotels_code_no_reviews: LANG.hotels_code_no_reviews || 'LANG.hotels_code_no_reviews',
    hotels_code_rated_user_reviews: LANG.hotels_code_rated_user_reviews || 'LANG.hotels_code_rated_user_reviews',
    hotels_code_facilities: LANG.hotels_code_facilities || 'LANG.hotels_code_facilities',
    hotels_code_view_full_list: LANG.hotels_code_view_full_list || 'LANG.hotels_code_view_full_list',
    hotels_code_reviews: LANG.hotels_code_reviews || 'LANG.hotels_code_reviews',
    hotels_code_currently_no_reviews: LANG.hotels_code_currently_no_reviews || 'LANG.hotels_code_currently_no_reviews',
    hotels_code_do_you_like_hotel: LANG.hotels_code_do_you_like_hotel || 'LANG.hotels_code_do_you_like_hotel',
    hotels_code_similar_hotels: LANG.hotels_code_similar_hotels || 'LANG.hotels_code_similar_hotels',
    hotels_code_free_extra: LANG.hotels_code_free_extra || 'LANG.hotels_code_free_extra',
    hotels_code_free_extras: LANG.hotels_code_free_extras || 'LANG.hotels_code_free_extras',
    hotels_code_explore_location: LANG.hotels_code_explore_location || 'LANG.hotels_code_explore_location',
    hotels_code_explore_on_pe: LANG.hotels_code_explore_on_pe || 'LANG.hotels_code_explore_on_pe',
    hotels_code_gallery_hotel: LANG.hotels_code_gallery_hotel || 'LANG.hotels_code_gallery_hotel',
    hotels_code_conc_booking_hotel: LANG.hotels_code_conc_booking_hotel || 'LANG.hotels_code_conc_booking_hotel',
    hotels_code_conc_booking_w_pe: LANG.hotels_code_conc_booking_w_pe || 'LANG.hotels_code_conc_booking_w_pe',
    hotels_code_youre_all_set_well_organize: LANG.hotels_code_youre_all_set_well_organize || 'LANG.hotels_code_youre_all_set_well_organize',
    hotels_code_name: LANG.hotels_code_name || 'LANG.hotels_code_name',
    hotels_code_surname: LANG.hotels_code_surname || 'LANG.hotels_code_surname',
    hotels_code_email_or_phone: LANG.hotels_code_email_or_phone || 'LANG.hotels_code_email_or_phone',
    hotels_code_how_many_people: LANG.hotels_code_how_many_people || 'LANG.hotels_code_how_many_people',
    hotels_code_date_from: LANG.hotels_code_date_from || 'LANG.hotels_code_date_from',
    hotels_code_date_to: LANG.hotels_code_date_to || 'LANG.hotels_code_date_to',
    hotels_code_request_offer: LANG.hotels_code_request_offer || 'LANG.hotels_code_request_offer',
    hotels_code_non_binding_offer: LANG.hotels_code_non_binding_offer || 'LANG.hotels_code_non_binding_offer',
    hotels_code_need_help_w_booking: LANG.hotels_code_need_help_w_booking || 'LANG.hotels_code_need_help_w_booking',
    hotels_code_email_patricia: LANG.hotels_code_email_patricia || 'LANG.hotels_code_email_patricia',
    hotels_code_direct_booking_w_hotel: LANG.hotels_code_direct_booking_w_hotel || 'LANG.hotels_code_direct_booking_w_hotel',
    hotels_code_direct_booking_hotel: LANG.hotels_code_direct_booking_hotel || 'LANG.hotels_code_direct_booking_hotel',
    hotels_code_book_directly_w_hotel_lose_pe_extras: LANG.hotels_code_book_directly_w_hotel_lose_pe_extras || 'LANG.hotels_code_book_directly_w_hotel_lose_pe_extras',
    hotels_code_visit_hotel_website: LANG.hotels_code_visit_hotel_website || 'LANG.hotels_code_visit_hotel_website',
    hotels_code_youll_be_redirected: LANG.hotels_code_youll_be_redirected || 'LANG.hotels_code_youll_be_redirected',
    hotels_code_make_sure_you_receive_all: LANG.hotels_code_make_sure_you_receive_all || 'LANG.hotels_code_make_sure_you_receive_all',
    hotels_code_contact_patricia_and_our_team: LANG.hotels_code_contact_patricia_and_our_team || 'LANG.hotels_code_contact_patricia_and_our_team',
    hotels_code_dont_lose_free_pe_extras: LANG.hotels_code_dont_lose_free_pe_extras || 'LANG.hotels_code_dont_lose_free_pe_extras',
    hotels_code_switch_to_conc_booking: LANG.hotels_code_switch_to_conc_booking || 'LANG.hotels_code_switch_to_conc_booking',
    hotels_code_promo_code: LANG.hotels_code_promo_code || 'LANG.hotels_code_promo_code',
    hotels_code_use_promo_code: LANG.hotels_code_use_promo_code || 'LANG.hotels_code_use_promo_code',
    hotels_code_your_offer: LANG.hotels_code_your_offer || 'LANG.hotels_code_your_offer',
    hotels_code_summary_location: LANG.hotels_code_summary_location || 'LANG.hotels_code_summary_location',
    hotels_code_summary_pools: LANG.hotels_code_summary_pools || 'LANG.hotels_code_summary_pools',
    hotels_code_summary_rooms: LANG.hotels_code_summary_rooms || 'LANG.hotels_code_summary_rooms',
    hotels_code_summary_food: LANG.hotels_code_summary_food || 'LANG.hotels_code_summary_food',
    hotels_code_summary_special: LANG.hotels_code_summary_special || 'LANG.hotels_code_summary_special',
    hotels_code_summary_spa: LANG.hotels_code_summary_spa || 'LANG.hotels_code_summary_spa',
};
