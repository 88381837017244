if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
        value: function(predicate) {
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }
            var o = Object(this);
            var len = o.length >>> 0;
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }
            var thisArg = arguments[1];
            var k = 0;
            while (k < len) {
                var kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return kValue;
                }
                k++;
            }
            return undefined;
        }
    });
}

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import mapStore from './store/map';
const store = new Vuex.Store(mapStore);
window.store = store;

import HotelMap from  './components/HotelMap';
import ViewMap from './components/ViewMap';
import {translation} from "./misc/translation";

Vue.mixin({
    methods: {
        translate: string => translation[string] || string
    }
});

Vue.config.productionTip = false;

if(document.getElementById('HotelMap')) {
    new Vue({
        store: store,
        el: '#HotelMap',
        template: '<HotelMap/>',
        components: {HotelMap},
    });
}

if(document.getElementById('ViewMap')) {
    new Vue({
        store: store,
        el: '#ViewMap',
        template: '<ViewMap/>',
        components: {ViewMap},
    });
}
